@import '../../../scss/theme-bootstrap';

.content-formatter {
  max-width: $max-width;
  margin: auto;
  padding: 20px 0 25px;
  .multi_use_homepage_module & {
    padding: 0;
  }
  .slick-dots {
    bottom: -5px;
  }
  &--full {
    max-width: 100%;
    .max-width {
      max-width: 100%;
    }
  }
  @media #{$medium-up} {
    padding: 40px 0 50px;
  }
  &__text {
    text-align: center;
    margin: 15px;
    @media #{$small-only} {
      min-width: 300px;
    }
    @media #{$medium-up} {
      margin: 30px;
    }
    @media #{$medium-up} {
      &--50 {
        max-width: 50%;
      }
      &--60 {
        max-width: 60%;
      }
      &--70 {
        max-width: 70%;
      }
      &--80 {
        max-width: 80%;
      }
      &--90 {
        max-width: 90%;
      }
    }
    &.content-formatter__text--0 {
      margin: 0;
    }
    &.content-formatter__text--10 {
      margin: 10px;
    }
    &.content-formatter__text--15 {
      margin: 15px;
    }
    &.content-formatter__text--20 {
      margin: 20px;
    }
    &.content-formatter__text--30 {
      margin: 30px;
    }
    &.content-formatter__text--40 {
      margin: 40px;
    }
    &.content-formatter__text--50 {
      margin: 50px;
    }
  }
  &__line {
    margin-top: 10px;
    &--title {
      @include headline--primary;
      font-size: 32px;
      @media #{$medium-up} {
        font-size: 45px;
      }
    }
    &--subtitle {
      @include headline--senary;
    }
    &--body {
      p {
        margin: 8px 0;
        line-height: 1.2;
        font-size: 16px;
      }
    }
    p {
      font-size: inherit;
      font-family: inherit;
      letter-spacing: inherit;
      line-height: inherit;
      &:only-of-type {
        margin: 0;
      }
    }
    &.font--akzidenzregularbq,
    &.font--akzidenz-bold {
      h1,
      h2,
      h3,
      h4,
      h5,
      div,
      p {
        font-family: $akzidenz;
        font-weight: bold;
      }
    }
  }
  .slick-initialized {
    @include carousel_arrows;
    touch-action: pan-y;
    padding-bottom: 30px;
    .slick-arrow {
      background-size: 20px 33px;
      z-index: 91;
      position: absolute;
      top: 25%;
      width: 40px;
      height: 80px;
      opacity: 0.5;
      transform: translateY(-50%);
      margin-top: 0;
      transition: all 0.3s;
      &.slick-disabled {
        opacity: 0.2;
      }
      @media #{$medium-up} {
        top: 35%;
      }
    }
    .slick-prev {
      #{$ldirection}: -1px;
    }
    .slick-next {
      #{$rdirection}: -1px;
      transform: translateY(-50%) rotate(180deg);
    }
  }
  &__rendered.slick-not-needed,
  &__rendered:not(.slick-initialized) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
  @media #{$small-only} {
    &[data-columns-small='1'] {
      .content-formatter__rendered-item {
        flex: 0 1 100%;
      }
    }
    &[data-columns-small='2'] {
      .content-formatter__rendered-item {
        flex: 0 1 50%;
      }
    }
    &[data-columns-small='3'] {
      .content-formatter__rendered-item {
        flex: 0 1 33%;
      }
    }
    &-small-padding {
      .slick-list {
        padding: 0 35px;
      }
    }
    &:not(.content-formatter-small-padding) {
      .content-block-tout__text-wrapper {
        padding: 0 22px;
      }
    }
    &-small-padding--5 {
      .content-formatter__rendered-item {
        padding: 0 2.5px;
      }
    }
    &-small-padding--10 {
      .content-formatter__rendered-item {
        padding: 0 5px;
      }
    }
    &-small-padding--15 {
      .content-formatter__rendered-item {
        padding: 0 7.5px;
      }
    }
    &-small-padding--20 {
      .content-formatter__rendered-item {
        padding: 0 10px;
      }
    }
    &-small-margin--10 {
      .content-formatter__rendered-item {
        margin-bottom: 10px;
      }
    }
    &-small-margin--15 {
      .content-formatter__rendered-item {
        margin-bottom: 15px;
      }
    }
    &-small-margin--20 {
      .content-formatter__rendered-item {
        margin-bottom: 20px;
      }
    }
    &-small-margin--25 {
      .content-formatter__rendered-item {
        margin-bottom: 25px;
      }
    }
    &-small-margin--30 {
      .content-formatter__rendered-item {
        margin-bottom: 30px;
      }
    }
    &-small-margin--35 {
      .content-formatter__rendered-item {
        margin-bottom: 35px;
      }
    }
    &-small-margin--40 {
      .content-formatter__rendered-item {
        margin-bottom: 40px;
      }
    }
  }
  @media #{$medium-up} {
    .slick-carousel--peeking .slick-list {
      padding: 0 45px;
    }
    &-medium-padding--10 {
      .content-formatter__rendered-item {
        padding: 0 5px;
      }
    }
    &-medium-padding--15 {
      .content-formatter__rendered-item {
        padding: 0 7.5px;
      }
    }
    &-medium-padding--20 {
      .content-formatter__rendered-item {
        padding: 0 10px;
      }
    }
    &-medium-padding--30 {
      .content-formatter__rendered-item {
        padding: 0 15px;
      }
    }
    &-medium-padding--40 {
      .content-formatter__rendered-item {
        padding: 0 20px;
      }
    }
    &-medium-margin--10 {
      .content-formatter__rendered-item {
        margin-bottom: 10px;
      }
    }
    &-medium-margin--15 {
      .content-formatter__rendered-item {
        margin-bottom: 15px;
      }
    }
    &-medium-margin--20 {
      .content-formatter__rendered-item {
        margin-bottom: 20px;
      }
    }
    &-medium-margin--25 {
      .content-formatter__rendered-item {
        margin-bottom: 25px;
      }
    }
    &-medium-margin--30 {
      .content-formatter__rendered-item {
        margin-bottom: 30px;
      }
    }
    &-medium-margin--35 {
      .content-formatter__rendered-item {
        margin-bottom: 35px;
      }
    }
    &-medium-margin--40 {
      .content-formatter__rendered-item {
        margin-bottom: 40px;
      }
    }
    &[data-columns-medium='1'] {
      .content-formatter__rendered-item {
        flex: 0 1 100%;
      }
    }
    &[data-columns-medium='2'] {
      .content-formatter__rendered-item {
        flex: 0 1 50%;
      }
    }
    &[data-columns-medium='3'] {
      .content-formatter__rendered-item {
        flex: 0 1 33%;
      }
    }
    &[data-columns-medium='4'] {
      .content-formatter__rendered-item {
        flex: 0 1 25%;
      }
    }
  }
  @media #{$large-up} {
    &[data-columns-large='1'] {
      .content-formatter__rendered-item {
        flex: 0 1 100%;
      }
    }
    &[data-columns-large='2'] {
      .content-formatter__rendered-item {
        flex: 0 1 50%;
      }
    }
    &[data-columns-large='3'] {
      .content-formatter__rendered-item {
        flex: 0 1 33%;
      }
    }
    &[data-columns-large='4'] {
      .content-formatter__rendered-item {
        flex: 0 1 25%;
      }
    }
    &[data-columns-large='5'] {
      .content-formatter__rendered-item {
        flex: 0 1 20%;
      }
    }
  }
  &__rendered--fill:not(.slick-initialized) {
    justify-content: space-evenly;
    flex-wrap: nowrap;
    .content-formatter__rendered-item {
      flex-grow: 1;
    }
  }
  @media #{$small-only} {
    &.content-top-padding-small--0 {
      padding-top: 0;
    }
    &.content-top-padding-small--10 {
      padding-top: 10px;
    }
    &.content-top-padding-small--15 {
      padding-top: 15px;
    }
    &.content-top-padding-small--20 {
      padding-top: 20px;
    }
    &.content-top-padding-small--25 {
      padding-top: 25px;
    }
    &.content-top-padding-small--30 {
      padding-top: 30px;
    }
    &.content-top-padding-small--40 {
      padding-top: 40px;
    }
    &.content-top-padding-small--50 {
      padding-top: 50px;
    }
    &.content-top-padding-small--75 {
      padding-top: 75px;
    }
    &.content-top-padding-small--100 {
      padding-top: 100px;
    }
    &.content-bottom-padding-small--0 {
      padding-bottom: 0;
    }
    &.content-bottom-padding-small--10 {
      padding-bottom: 10px;
    }
    &.content-bottom-padding-small--15 {
      padding-bottom: 15px;
    }
    &.content-bottom-padding-small--20 {
      padding-bottom: 20px;
    }
    &.content-bottom-padding-small--25 {
      padding-bottom: 25px;
    }
    &.content-bottom-padding-small--30 {
      padding-bottom: 30px;
    }
    &.content-bottom-padding-small--40 {
      padding-bottom: 40px;
    }
    &.content-bottom-padding-small--50 {
      padding-bottom: 50px;
    }
    &.content-bottom-padding-small--75 {
      padding-bottom: 75px;
    }
    &.content-bottom-padding-small--100 {
      padding-bottom: 100px;
    }
  }
  @media #{$medium-up} {
    &.content-top-padding--0 {
      padding-top: 0;
    }
    &.content-top-padding--10 {
      padding-top: 10px;
    }
    &.content-top-padding--15 {
      padding-top: 15px;
    }
    &.content-top-padding--20 {
      padding-top: 20px;
    }
    &.content-top-padding--30 {
      padding-top: 30px;
    }
    &.content-top-padding--40 {
      padding-top: 40px;
    }
    &.content-top-padding--50 {
      padding-top: 50px;
    }
    &.content-top-padding--75 {
      padding-top: 75px;
    }
    &.content-top-padding--100 {
      padding-top: 100px;
    }
    &.content-bottom-padding--0 {
      padding-bottom: 0;
    }
    &.content-bottom-padding--10 {
      padding-bottom: 10px;
    }
    &.content-bottom-padding--15 {
      padding-bottom: 15px;
    }
    &.content-bottom-padding--20 {
      padding-bottom: 20px;
    }
    &.content-bottom-padding--30 {
      padding-bottom: 30px;
    }
    &.content-bottom-padding--40 {
      padding-bottom: 40px;
    }
    &.content-bottom-padding--50 {
      padding-bottom: 50px;
    }
    &.content-bottom-padding--75 {
      padding-bottom: 75px;
    }
    &.content-bottom-padding--100 {
      padding-bottom: 100px;
    }
  }
  .content-anchor {
    position: relative;
    top: -100px;
    visibility: hidden;
  }
}
